






import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';
import LoginForm from '@/modules/entrance/components/forms/LoginForm.vue';
import { inject } from '@corefy/inversify-nuxt';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { AlertService } from '@corefy/ui/services/alert/AlertService';
import { Alert } from '@/shared/global-alerts/enumerations/alerts';
import { alertsAssets } from '@/shared/global-alerts/assets/alertsAssets';
import { BlogStore } from '@/shared/blog/store/BlogStore';
import { Context } from '@nuxt/types';
import { omit } from 'lodash';

@Component({
  layout: 'EntranceSplittedLayout',
  components: {
    LoginForm,
  },
})
export default class LoginPage extends Vue {
  async asyncData(ctx: Context) {
    const blogStore = ctx.$container.get<BlogStore>(iocTypes.BlogStore)
    await blogStore.getPosts();
  }

  @inject(iocTypes.AlertService)
  private alertService!: AlertService;

  mounted() {
    this.checkRouteAssets();
    this.checkEmail();
  }

  private email = '';

  private clearUrlQuery() {
    this.$router.replace({ query: omit(this.$route.query, ['alert', 'email', 'activated']) });
  }

  private checkEmail() {
    // Activated its email to, but related with registration flow
    const { email, activated } = this.$route.query;
    if (!email && !activated) return;

    this.email = (email as string) || (activated as string);

    if (activated) this.showSuccessAlert();

    this.clearUrlQuery();
  }

  private getLayoutEl() {
    return document.querySelector('#entrance-split')?.querySelector('.entrance__first');
  }

  private checkAlerts(alert: Alert) {
    const alertAsset = alertsAssets.getFor(alert);

    if (alertAsset) {
      const referenceEl = this.getLayoutEl();
      this.alertService.showAlert({
        reference: referenceEl || undefined,
        position: referenceEl ? 'center' : 'end',
        disableClose: false,
        color: alertAsset.color,
        title: this.$t(alertAsset.title) as string,
        message: (this.$t(alertAsset.description || '') as string) || undefined,
        ...(alertAsset.icon
          ? { icon: { name: alertAsset.icon.name, type: alertAsset.icon.type } }
          : {}),
      });
      this.clearUrlQuery();
    } else {
      console.error(`Alert ${alert} is not presented in alertsAssets`);
    }
  }

  private showSuccessAlert() {
    const referenceEl = this.getLayoutEl();

    this.alertService.showAlert({
      reference: referenceEl || undefined,
      position: referenceEl ? 'center' : 'end',
      title: this.$t('entrance__activation_acc__success') as string,
      message: this.$t('entrance__activation_acc__description') as string,
      color: 'success',
      icon: {
        name: 'circle-check',
        type: 'solid',
      },
    });
  }

  private checkRouteAssets() {
    const { alert } = this.$route.query;
    if (alert) {
      this.checkAlerts(alert as Alert);
    }
  }
}
