var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"login-form"},[_c('FormHeader',{attrs:{"title":_vm.$t('entrance__login_title')}}),_vm._v(" "),_c('UiForm',{staticClass:"login-form__form",attrs:{"validation-controller":_vm.validationController},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('UiFormItem',{staticClass:"login-form__form-email",attrs:{"field-name":"login","label":_vm.$t('entrance__email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var clearValidation = ref.clearValidation;
var valid = ref.valid;
return [_c('UiInput',{ref:"email",attrs:{"type":"text","readonly":_vm.formLoading,"disabled":!!_vm.email,"placeholder":_vm.$t('entrance__email_placeholder'),"data-e2e":"email-input","data-testid":"email-input"},on:{"input":clearValidation,"blur":validate},scopedSlots:_vm._u([(valid && _vm.form.login)?{key:"suffix",fn:function(){return [_c('UiIcon',{attrs:{"name":"check","color":"success","type":"solid"}})]},proxy:true}:null],null,true),model:{value:(_vm.form.login),callback:function ($$v) {_vm.$set(_vm.form, "login", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.login"}})]}}])}),_vm._v(" "),_c('UiFormItem',{staticClass:"login-form__form-password reset-margin",attrs:{"controller":_vm.validationController,"label":_vm.$t('entrance__password'),"field-name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var clearValidation = ref.clearValidation;
return [_c('UiInput',{ref:"password",attrs:{"readonly":_vm.formLoading,"type":_vm.inputType,"placeholder":_vm.$t('entrance__password_placeholder'),"data-e2e":"password-input","data-testid":"password-input"},on:{"input":clearValidation,"blur":validate},scopedSlots:_vm._u([(_vm.form.password)?{key:"suffix",fn:function(){return [_c('UiButton',{attrs:{"size":"mini","type":"tritary","icon-only":"","color":"info"},on:{"click":_vm.toggleInputType}},[_c('UiIcon',{attrs:{"name":_vm.iconName}})],1)]},proxy:true}:null],null,true),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_vm._v(" "),_c('UiRouterLink',{staticClass:"login-form__form-password-hint",attrs:{"to":_vm.routePath}},[_vm._v("\n        "+_vm._s(_vm.$t('entrance__forgot_pass'))+"\n      ")])]}}])}),_vm._v(" "),_c('UiFormControls',[_c('UiButton',{attrs:{"fluid":"","size":"medium","native-type":"submit","loading":_vm.formLoading,"data-e2e":"submit-button","data-testid":"submit-button"}},[_vm._v("\n        "+_vm._s(_vm.$t('entrance__login'))+"\n      ")])],1)],1),_vm._v(" "),(_vm.isRegistrationEnabled)?_c('div',{staticClass:"login-form__question form-suffix"},[_c('p',[_vm._v(_vm._s(_vm.$t('entrance__new_to_corefy')))]),_vm._v(" "),_c('UiRouterLink',{staticClass:"form-suffix__link",attrs:{"size":"mini","to":{ name: 'registration' }}},[_vm._v("\n      "+_vm._s(_vm.$t('entrance__create_acc'))+"\n    ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }