





















































































import Vue from 'vue';
import { AsyncFormValidationControllerFactory } from '@corefy/vue-validation/services/AsyncFormValidationController';
import { UserStore } from '@/shared/user/store/UserStore';
import { Component, Prop, Watch } from 'nuxt-property-decorator';
import { iocTypes } from '@/shared/ioc/iocTypes';
import { inject } from '@corefy/inversify-nuxt';
import { UiInput } from '@corefy/ui/components/ui-input';
import { UiPasswordInput } from '@corefy/ui/components/ui-password-input';
import { Button, ErrorAlertService } from '@/shared/errors/services/ErrorAlertService';
import { ORG_LIST_ROUTE_PATH } from '@/router/consts/route-names';
import { UNVERIFIED_EMAIL } from '@/shared/errors/const/httpErrorsMap';
import { RegistrationStore } from '@/shared/registration/RegistrationStore';
import { ValidationResult } from '@corefy/validation/interfaces/Validator';
import { AsyncValidatorBuilderFactory } from '@/shared/validation/services/AsyncValidatorBuilder';
import { AppDataStore } from '@/shared/app-data/store/AppDataStore';

interface Form {
  login: string;
  password: string;
}

const TWO_AUTH_ROUTE = '2fa';

@Component
export default class LoginForm extends Vue {
  $refs!: {
    email: UiInput;
    password: UiPasswordInput;
  };

  @inject(iocTypes.ErrorAlertService)
  errorAlertService!: ErrorAlertService;

  @inject(iocTypes.RegistrationStore)
  registrationStore!: RegistrationStore;

  @inject(iocTypes.AsyncValidatorBuilderFactory)
  readonly createValidator!: AsyncValidatorBuilderFactory;

  @inject(iocTypes.AsyncFormValidationControllerFactory)
  createController!: AsyncFormValidationControllerFactory;

  @inject(iocTypes.AppDataStore)
  addData!: AppDataStore;

  @inject(iocTypes.UserStore)
  readonly userStore!: UserStore;

  @Prop({ type: String })
  readonly email?: string;

  async mounted() {
    this.focusOnInput();
    await this.validationController.initialize({ shallow: true });
  }

  inputType: 'password' | 'text' = 'password';
  routePath: { name: string; query?: any } = {
    name: 'restore-password',
  };

  form: Form = {
    login: '',
    password: '',
  };

  validationController = this.createController<Form>({
    form: () => this.form,
    validator: ({ validateAll }) => ({
      login: this.createValidator().setRequired(validateAll).useCustom.emailValidator().getResult(),
      password: this.createValidator().setRequired(validateAll).getResult(),
    }),
  });

  @Watch('loginValidation')
  loginValidationHandler(result: ValidationResult | undefined) {
    this.routePath = result?.valid
      ? {
          name: 'restore-password',
          query: {
            email: this.form.login,
          },
        }
      : {
          name: 'restore-password',
        };
  }

  @Watch('email', { immediate: true })
  emailHandler(email: string) {
    if (email) this.form.login = email;
  }

  get isRegistrationEnabled() {
    return this.addData.data.env.REGISTRATION_DISABLED !== 'true';
  }

  get loginValidation() {
    return this.validationController.validationResult.login;
  }

  get loginIndicators() {
    return this.userStore.loginIndicators;
  }

  get formLoading() {
    return this.loginIndicators.execution;
  }

  get iconName() {
    return this.inputType === 'password' ? 'eye' : 'eye-slash';
  }

  toggleInputType() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
  }

  focusOnInput() {
    if (this.email) {
      this.$refs.password.focus();
      return;
    }

    this.$refs.email.focus();
  }

  getLayoutEl() {
    return document.querySelector('#entrance-split')?.querySelector('.entrance__first');
  }

  dismissHandler(payload?: { close: () => void }) {
    payload?.close();
  }

  async resendVerifyLinkHandler(payload?: { close: () => void }) {
    await this.registrationStore.registrationConfirmationResend({
      email: this.form.login,
    });
    if (this.registrationStore.registrationConfirmationResendIndicators.error) {
      this.errorAlertService.showError({
        error: this.registrationStore.registrationConfirmationResendIndicators.error,
        position: 'center',
        reference: this.getLayoutEl() || undefined,
      });
    } else {
      payload?.close();
    }
  }

  errorHandling() {
    const referenceEl = this.getLayoutEl();

    let buttons: Array<Button> = [];
    if (this.loginIndicators.error?.name === UNVERIFIED_EMAIL) {
      buttons = [
        {
          text: this.$t('alert__dismiss') as string,
          type: 'tritary',
          color: 'info',
          handler: this.dismissHandler,
        },
        {
          text: this.$t('alert__resend_link') as string,
          type: 'tritary',
          color: 'primary',
          handler: this.resendVerifyLinkHandler,
        },
      ];
    }

    this.errorAlertService.showError({
      error: this.userStore.loginIndicators.error!,
      color: this.loginIndicators.error?.name === UNVERIFIED_EMAIL ? 'warning' : 'danger',
      icon:
        this.loginIndicators.error?.name === UNVERIFIED_EMAIL
          ? { name: 'triangle-exclamation', type: 'solid' }
          : undefined,
      reference: referenceEl || undefined,
      position: referenceEl ? 'center' : 'end',
      buttons,
    });
  }

  async submit() {
    await this.validationController.validateForm();

    if (this.validationController.allValid) {
      await this.userStore.login(this.form);

      if (this.userStore.loginIndicators.success) {
        if (this.userStore.TFARequired) {
          await this.$router.push({ name: TWO_AUTH_ROUTE });
        } else {
          window.location.href = ORG_LIST_ROUTE_PATH;
        }
      } else if (this.userStore.loginIndicators.error) {
        this.errorHandling();
      }
    }
  }
}
