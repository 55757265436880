import { Alert } from '@/shared/global-alerts/enumerations/alerts';
import { AlertColor } from '@corefy/ui/components/alert';
import { IconType } from '@corefy/ui/components/ui-icon';
import { AssetsProvider } from '@/shared/common/interfaces/AssetsProvider';

interface AlertAsset {
  icon?: {
    name: string;
    type: IconType;
  };
  title: string;
  description?: string;
  color: AlertColor;
}

const modalMap = {
  [Alert.IncorrectLink]: {
    title: 'alert__incorrect_registration_link',
    color: 'danger' as const,
  },
  [Alert.SomethingWentWrong]: {
    title: 'alert__something_wrong',
    color: 'danger' as const,
  },
  [Alert.InviteHasExpired]: {
    title: 'alert__invite_has_expired',
    description: 'alert__invite_has_expired__description',
    color: 'danger' as const,
    icon: {
      type: 'solid' as const,
      name: 'triangle-exclamation',
    },
  },
  [Alert.PasswordChanged]: {
    title: 'entrance__resetting_pass_confirm__success',
    description: 'entrance__resetting_pass_confirm__description',
    color: 'success' as const,
    icon: {
      type: 'solid' as const,
      name: 'circle-check',
    },
  },
  [Alert.InviteNotFoundOrRevoked]: {
    title: 'entrance__invite_revoked_or_not_found',
    description: 'entrance__invite_revoked_or_not_found__description',
    color: 'danger' as const,
    icon: {
      type: 'solid' as const,
      name: 'diamond-exclamation',
    },
  },
  [Alert.TokenNotFoundOrUsed]: {
    title: 'entrance__token_not_found_or_used',
    description: 'entrance__token_not_found_or_used__description',
    color: 'danger' as const,
    icon: {
      type: 'solid' as const,
      name: 'diamond-exclamation',
    },
  },
};

export const alertsAssets: AssetsProvider<Alert, AlertAsset | null> = {
  getFor(value: Alert): AlertAsset | null {
    return modalMap[value] || null;
  },
};
